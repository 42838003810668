import React, { Fragment } from 'react';

import Layout from '../components/layout';
import SignInForm, { SignInGoogle } from '../components/SignIn';
// import { SignUpLink } from '../components/SignUp';
import { PasswordForgetLink } from '../components/PasswordForget';
import SectionWrapper from '../components/SectionWrapper/SectionWrapper';

const SignInPage = () => (
  <Fragment>
    <h1>Sign In</h1>
    <SignInForm />
    <SignInGoogle />
    <PasswordForgetLink />
    {/* <SignUpLink /> */}
  </Fragment>
);

export default () => (
  <Layout>
    <SectionWrapper sectionId="signin">
      <SignInPage />
    </SectionWrapper>
  </Layout>
);
